var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-5"},[[_c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"loading":!_vm.isDownloaded,"headers":_vm.headers,"items":_vm.tasks,"item-key":"id","hide-default-footer":true,"disable-pagination":true,"sort-desc":false},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date.substr(0, 10))+" ")]}},{key:"item.clientId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.clientId ? item.clientId.name : '')+" ")]}},{key:"item.dateCp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateCp.substr(0, 10))+" "+_vm._s(_vm.correctDate(item.dateCp))+" ")]}},{key:"item.copyRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.copyRate.toFixed(2))+" ")]}},{key:"item.fullRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullRate.toFixed(2))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.color(item.status),"text-color":"white"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"mt-1 mb-1 mr-2 white--text",attrs:{"color":"green","loading":_vm.isLoadedTake},on:{"click":function($event){$event.stopPropagation();return _vm.takeTask(item._id)}}},[_vm._v(" Przyjmij zadanie ")]),_c('ShowDescription',{attrs:{"description":item.description}})],1)]}}])}),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" "),[_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" Zamknij ")])]],2)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }