<template>
        <div>
            <v-btn
				class="white--text"
                color="green"
                dark
                medium
                @click="popup = !popup"
            >
                Podejrzyj
            </v-btn>
		<v-dialog
            v-model="popup"
            max-width="500px"
        >
            <v-card>
                    <v-card-title>
                        Opis zadania
                    </v-card-title>
                    <v-card-text class="text-left" v-html="description">
                    </v-card-text>
            </v-card>
        </v-dialog>
        </div>
</template>

<script>
export default{
    name: 'Popup',
    props: {
        description: {
            type: String
        }
    },
    data(){
        return{
            popup: false,
        }
    },
}
</script>
